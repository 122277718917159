
window.TestimonialSlider = (randomId) => {
	return {
		swiper: null,
		init() {
			setTimeout(() => {
				this.swiper_news = new Swiper(".swiper-container" + randomId, {
					loop: true,
					slidesPerView: 1,
					pagination: {
						el: ".swiper-pagination" + randomId,
						clickable: true,
						type: "bullets",
					},
					autoplay: {
						delay: 5000,
					},
				});
			}, 0);
		},
	};
};
